@use '../../styles/common.scss' as common;

.logo {
	width: 200px;
	height: 200px;
	margin-left: 20px;

	@media (max-width: common.$large-view-width) {
		width: 100px;
		height: 100px;
	}
}
