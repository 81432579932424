.clickable {
	cursor: pointer;
}

.light {
	filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(21deg) brightness(97%) contrast(103%);
}

.dark {
	filter: invert(100%) sepia(1%) saturate(7498%) hue-rotate(103deg) brightness(104%) contrast(101%);
}
