@use '../../styles/colors.scss' as colors;
@use '../../styles/common.scss' as common;

.container {
	z-index: 200;
	flex: 3;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	@media (max-width: common.$large-view-width) {
		flex: 5;
	}

	.content {
		z-index: 100;
		padding: 16px;
		position: absolute;

		.title {
			color: colors.$text-primary;
		}
	}

	.image {
		width: 100%;
		height: 100%;
		background-color: colors.$figures-card;
		transition: 300ms ease-in-out;
	}

	.imageHover {
		filter: brightness(80%);
	}
}
