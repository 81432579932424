@use '../../styles/common.scss' as common;
@use '../../styles/colors.scss' as colors;

.wrapper {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	color: colors.$text-primary;
	background-color: colors.$background-primary;

	.container {
		margin: 48px 10vw;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		@media (max-width: common.$large-view-width) {
			margin: 16px;
		}

		.title {
			font-size: 40px;
			font-weight: 800;
			@media (max-width: common.$large-view-width) {
				font-size: 28px;
			}
		}

		.description {
			font-size: 20px;
			font-weight: 400;
			padding-bottom: 16px;
			@media (max-width: common.$large-view-width) {
				font-size: 16px;
			}
		}

		.link {
			font-size: 20px;
			font-weight: 400;
			cursor: pointer;
			@media (max-width: common.$large-view-width) {
				font-size: 16px;
			}
		}
	}
}
