@use '../../styles/common.scss' as common;
@use '../../styles/colors.scss' as colors;

.wrapper {
	color: colors.$text-dark-blue;
	background-color: colors.$strategy-page;

	a {
		color: colors.$text-dark-blue;
	}

	a:visited {
		color: colors.$text-dark-blue;
	}

	.strategyContainer {
		gap: 16px;
		display: flex;
		align-items: center;
		flex-direction: column;

		.strategy {
			width: 478px;
			height: 335px;
			@media (max-width: common.$large-view-width) {
				max-width: 250px;
			}
			@media (max-width: common.$large-view-width) {
				width: 243px;
				height: 170px;
			}
		}
	}
}
