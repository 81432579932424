@use '../../styles/common.scss' as common;
@use '../../styles/colors.scss' as colors;

.wrapper {
	color: colors.$text-primary;
	background-color: colors.$values-page;

	.valueContainer {
		gap: 16px;
		display: flex;
		align-items: center;
		flex-direction: column;

		.values {
			width: 500px;
			box-sizing: border-box;
			@media (max-width: common.$large-view-width) {
				max-width: 275px;
			}
		}

		.valueHead {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.valueMiddle {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.valueTail {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.modal {
			color: colors.$values-1;
		}

		.engaged {
			color: colors.$values-2;
		}

		.common {
			color: colors.$values-3;
		}

		.depend {
			color: colors.$values-4;
		}

		.dynamic {
			color: colors.$values-5;
		}

		.responsible {
			color: colors.$values-6;
		}

		.textContainer {
			max-width: 600px;
			display: flex;
			flex-direction: column;
		}
	}
}
