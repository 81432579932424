@use '../../styles/common.scss' as common;
@use '../../styles/colors.scss' as colors;

.wrapper {
	color: colors.$text-primary;
	background-color: colors.$figures-page;

	a {
		color: colors.$text-primary;
	}

	a:visited {
		color: colors.$text-primary;
	}

	.figuresContainer {
		gap: 16px;
		display: flex;
		align-items: center;
		flex-direction: column;

		.figures {
			width: 425px;
			height: 420px;
			@media (max-width: common.$large-view-width) {
				max-width: 250px;
			}
			@media (max-width: common.$large-view-width) {
				width: 195px;
				height: 170px;
			}
		}
	}
}
