@use '../../styles/common.scss' as common;
@use '../../styles/colors.scss' as colors;

.wrapper {
	color: colors.$text-primary;
	background-color: colors.$waypoint-page;

	a {
		color: colors.$text-primary;
	}

	a:visited {
		color: colors.$text-primary;
	}

	.waypointContainer {
		display: flex;
		align-items: center;
		flex-direction: column;

		.waypointTabs {
			width: 100%;
			display: flex;
			@media (max-width: common.$large-view-width) {
				width: 100%;
			}

			.tab {
				padding: 8px 0;
				gap: 4px;
				flex: 1;
				display: flex;
				align-items: center;
				border-top: 4px solid colors.$waypoint-tab;
			}

			.activeTab {
				border-top: 4px solid colors.$waypoint-tab-active;
			}

			.tab:hover {
				cursor: pointer;
			}

			.tab:not(:last-child) {
				margin-right: 4px;
			}
		}

		.waypointImage {
			width: 450px;
			@media (max-width: common.$large-view-width) {
				max-width: 250px;
			}
		}

		.padding {
			padding-top: 16px;
		}
	}

	@media (max-width: common.$large-view-width) {
		.houseImage {
			max-width: 250px;
			align-self: center;
			margin-bottom: 32px;
		}
	}

	.padding {
		padding-bottom: 16px;
	}
}
