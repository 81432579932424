// colors variables live here

$text-primary: #ffffff;
$text-secondary: #bfbfbf;
$text-red: #ea3223;
$text-blue: #225088;
$text-dark-blue: #1e2640;

$link: #6cc5dc;
$divider: #707070;

$values-card: #c7565c;
$values-page: #c7565c;

$values-1: #225088;
$values-2: #f29791;
$values-3: #a8b179;
$values-4: #ff9c45;
$values-5: #b5e7f4;
$values-6: #fcc914;

$fedpol-card: #808e58d0;
$fedpol-page: #b0ca89;

$strategy-card: #ffffff;
$strategy-page: #ffffff;

$figures-card: #875d7f;
$figures-page: #8f5a81;

$waypoint-card: #5667a780;
$waypoint-page: #6581b2;

$waypoint-tab: #ffffff;
$waypoint-tab-active: #20263e;

$background-primary: #1f263f;
$background-secondary: #2d5084;
