@use '../../styles/colors.scss' as colors;
@use '../../styles/common.scss' as common;

.container {
	z-index: 200;
	flex: 3;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	@media (max-width: common.$large-view-width) {
		flex: 5;
	}

	.content {
		width: calc(100% - 32px);
		z-index: 100;
		padding: 16px;
		position: absolute;

		.title {
			color: colors.$text-blue;
			white-space: pre-line;
		}

		.cubeWrapper {
			display: flex;
			justify-content: space-between;
		}

		.cube {
			padding: 32px 0px 0px 0px;
			width: 171px;
			height: 120px;
			@media (max-width: common.$large-view-width) {
				padding: 0px;
				width: 91px;
				height: 64px;
			}
		}
	}

	.image {
		width: 100%;
		height: 100%;
		background-color: colors.$strategy-card;
		transition: 300ms ease-in-out;
	}

	.imageHover {
		filter: brightness(80%);
	}
}
