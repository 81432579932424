@use '../../styles/common.scss' as common;
@use '../../styles/colors.scss' as colors;
@use '../../styles/dimensions.scss' as dimensions;

.wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100vw;
	min-height: calc(100vh - #{dimensions.$header-height} - #{dimensions.$language-height});
	background-color: colors.$background-primary;

	.container {
		max-width: dimensions.$container-width;
		margin: 12px 48px;
		padding-bottom: 40px;
		display: flex;
		flex-direction: column;
		@media (max-width: common.$large-view-width) {
			margin: 0;
		}

		.header {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.titleContainer {
				max-width: 60%;
				@media (max-width: common.$large-view-width) {
					max-width: 100%;
					margin: 24px;
				}

				@media (max-width: common.$large-view-width) {
					.titleWrapper {
						display: flex;
						align-items: center;
						padding-bottom: 24px;
					}
				}

				.title {
					display: flex;
					padding-bottom: 24px;
					font-size: 46px;
					font-weight: 700;
					@media (max-width: common.$large-view-width) {
						flex-direction: column;
						padding-bottom: 0;
						font-size: 37px;
						font-weight: 700;
					}
					@media (max-width: common.$small-view-width) {
						font-size: 30px;
						font-weight: 600;
					}

					.welcome {
						color: colors.$text-primary;
						padding-right: 8px;

						.name {
							color: colors.$text-red;
						}
					}
				}

				.subtitle {
					font-size: 25px;
					font-weight: 400;
					color: colors.$text-primary;
					@media (max-width: common.$large-view-width) {
						font-size: 16px;
						font-weight: 400;
					}
					@media (max-width: common.$small-view-width) {
						font-size: 14px;
						font-weight: 400;
					}
				}
			}
		}

		.cards {
			gap: 8px;
			height: 500px;
			display: flex;
			padding: 24px 0 32px 0;

			.left {
				gap: 8px;
				flex: 1;
				display: flex;
				flex-direction: column;

				.vertical {
					gap: 8px;
					flex: 4;
					display: flex;
				}
			}

			.right {
				gap: 8px;
				flex: 1;
				display: flex;
				flex-direction: column;
			}
		}

		.digital {
			font-size: 20px;
			font-weight: 700;
			padding-bottom: 8px;
			color: colors.$text-primary;
		}

		@media (max-width: common.$large-view-width) {
			.digitalContainer {
				margin: 0px 32px;
			}
		}

		.socialContainer {
			display: flex;
			align-items: center;
			margin: 8px 0px;

			.link {
				color: colors.$link;
				padding-left: 16px;
			}
		}

		.divider {
			width: 100%;
			height: 1px;
			margin: 32px 0px;
			background-color: colors.$divider;
		}

		.imprintContainer {
			display: flex;
			justify-content: space-between;
			@media (max-width: common.$large-view-width) {
				padding: 32px;
				flex-direction: column;
			}

			.imprintLink {
				text-decoration: none;
				color: colors.$text-primary;

				.imprint {
					font-size: 20px;
					color: colors.$text-primary;
					@media (max-width: common.$large-view-width) {
						padding-bottom: 16px;
					}
				}
			}

			.imprintContent {
				display: flex;

				.imprintText {
					padding-left: 8px;
					display: flex;
					flex-direction: column;
					font-size: 13px;
					color: colors.$text-secondary;

					.department {
						padding-top: 16px;
					}

					.fedpol {
						font-weight: 600;
						color: colors.$text-primary;
					}
				}
			}
		}
	}
}
