@use '../styles/common.scss' as common;
@use '../styles/dimensions.scss' as dimensions;

.wrapper {
	min-height: calc(100vh - #{dimensions.$header-height});
}

.container {
	position: relative;
	height: calc(100vh - 128px - #{dimensions.$header-height});
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 64px 10vw;
	width: 80vw;

	@media (max-width: common.$large-view-width) {
		padding: 64px 5vw;
		width: 90vw;
	}

	@media (max-width: common.$large-view-width) {
		position: static;
		height: fit-content;
	}
}

.closeContainer {
	top: 64px;
	right: 10vw;
	position: absolute;

	@media (max-width: common.$large-view-width) {
		right: 5vw;
	}

	@media (max-width: common.$large-view-width) {
		top: calc(24px + #{dimensions.$header-height});
		right: 24px;
	}
}

.contentWrapper {
	gap: 64px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: common.$large-view-width) {
		display: flex;
		flex-direction: column;
	}
}

.contentContainer {
	gap: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	@media (max-width: common.$large-view-width) {
		display: flex;
		flex-direction: column;
	}
}

.textContainer {
	max-width: 600px;
	display: flex;
	flex-direction: column;
	@media (max-width: common.$large-view-width) {
		max-width: 100%;
	}
}

.header {
	font-size: 16px;
	font-weight: 300;
	padding-bottom: 16px;
}

.title {
	font-size: 46px;
	font-weight: 600;
	padding-bottom: 32px;
	word-break: break-word;
	white-space: pre-line;
	@media (max-width: common.$large-view-width) {
		font-size: 37px;
		word-wrap: break-word;
		width: 90vw;
	}
}

.description {
	a:visited {
		color: white;
	}
	font-size: 20px;
	font-weight: 300;
	@media (max-width: common.$large-view-width) {
		font-size: 16px;
	}
}
