@use '../../styles/dimensions.scss' as dimensions;
@use '../../styles/colors.scss' as colors;

.span {
	width: 98vw;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: dimensions.$language-height;
	color: colors.$text-primary;

	.languages {
		display: flex;
		padding-right: 8px;

		.language {
			margin: 0px 4px;
			padding: 2px 4px;
		}

		.language:hover {
			cursor: pointer;
		}

		.activeLanguage {
			border-radius: 4px;
			background-color: colors.$background-secondary;
		}
	}
}
