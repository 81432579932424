@use '../styles/common.scss' as common;

.title {
	max-width: 200px;
	font-size: 24px;
	font-weight: 600;
	padding-bottom: 8px;
	@media (max-width: common.$small-view-width) {
		font-size: 20px;
		font-weight: 600;
	}
}
